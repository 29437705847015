import { ChangeEvent, SyntheticEvent, useMemo, useState } from 'react';
import { Autocomplete as MuiAutocomplete, FormControl, TextField } from '@mui/material';
import {
  CoreBox,
  CoreCheckbox,
  CoreDialogContent,
  CoreDialogTitle,
  CoreFormControlLabel,
  CoreFormGroup,
  CoreTextField,
  CoreTypography,
} from '@youscience/khaleesi';
import { Season, PersonalInfo, ContactMethod, UserAddress } from '@youscience/college-service-common';

import useOptInStore from '@store/OptInStore';
import useUserPreferencesStore from '@store/UserPreferencesStore';
import useAuthStore, { ProfileWithExtendedData } from '@store/AuthStore';
import useUserInfoStore from '@store/UserInfoStore';

import DialogFooter from '@components/DialogFooter';
import { renderNoOptionsText } from '@components/Form/Autocomplete/Autocomplete';
import { InputMask } from '@components/Form/InputMask';

import { AutocompleteOption } from '@interfaces';
import { ContactFormHelperTexts, ErrorFieldsStatus } from '@interfaces/optIn';

import { validateEmail } from '@utils/form';

import { STATES_OF_USA } from '@constants';
import {
  MAX_ADDRESS_LENGTH,
  PLAN_ENROLLMENT_OPTIONS,
  INITIAL_ERROR_FIELDS_STATUS,
  HELPER_TEXT,
  INITIAL_CONTACT_FORM_HELPER_TEXTS,
  ADDRESS_HELPER_TEXT_LABELS,
} from '@constants/optIn';
import { ATTENDING_YEAR_BY_GRADE } from '@constants/educationPlanner';

import { sxStyles, StyledDialog } from './OptInContactForm.styles';

interface OptInContactFormProps {
  isOpenOptInContactForm: boolean;
  onGoBack: () => void;
  onGoNext: () => void;
}

export const OptInContactForm = (props: OptInContactFormProps) => {
  const { isOpenOptInContactForm, onGoBack, onGoNext } = props;

  const { personalInfo } = useUserPreferencesStore((state) => state.userPreferences);
  const { userInfo } = useUserInfoStore((state) => state);
  const { userData } = useAuthStore((state) => state.authSession);
  const { gradeLevel = 8 } = (userData?.profile ?? {}) as ProfileWithExtendedData;

  const resetOptInConnectionData = useOptInStore((state) => state.resetOptInConnectionData);
  const saveUserSharingPreferences = useUserPreferencesStore((state) => state.saveUserSharingPreferences);

  const initialFormState: PersonalInfo = {
    email: personalInfo?.email || userInfo?.email || '',
    phone: personalInfo?.phone || userInfo?.phone || '',
    address: {
      address: personalInfo?.address?.address || '',
      apartment: personalInfo?.address?.apartment || '',
      city: personalInfo?.address?.city || '',
      state: personalInfo?.address?.state || '',
      zip: personalInfo?.address?.zip || '',
    },
    planEnrollment: {
      season: personalInfo?.planEnrollment?.season || Season.FALL,
      year: personalInfo?.planEnrollment?.year || ATTENDING_YEAR_BY_GRADE[gradeLevel],
    },
    preferredMethodOfContact: {
      email: false,
      phone: false,
      mail: false,
      text: false,
      ...personalInfo?.preferredMethodOfContact,
    },
  };

  const [formState, setFormState] = useState<PersonalInfo>(initialFormState);
  const [formHelperTexts, setFormHelperTexts] = useState(INITIAL_CONTACT_FORM_HELPER_TEXTS);

  const [errorFieldsStatus, setErrorFieldsStatus] = useState(INITIAL_ERROR_FIELDS_STATUS);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const selectedState = useMemo(
    () => STATES_OF_USA.find((item) => item.label === formState?.address?.state) || null,
    [formState?.address?.state],
  );
  const selectedPlanEnrollment = useMemo(
    () =>
      PLAN_ENROLLMENT_OPTIONS.find(
        (item) =>
          item.label === `${formState.planEnrollment?.season as string} ${formState.planEnrollment?.year as number}`,
      ) || null,
    [formState.planEnrollment?.season, formState.planEnrollment?.year],
  );

  const updateFormValues = (fieldUpdates: Partial<PersonalInfo>) => {
    setFormState((prevState) => ({
      ...prevState,
      ...fieldUpdates,
    }));
  };

  const updateFormHelperTexts = (fieldUpdates: Partial<ContactFormHelperTexts>) => {
    setFormHelperTexts((prevState) => ({
      ...prevState,
      ...fieldUpdates,
    }));
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (errorFieldsStatus.email) {
      updateFormHelperTexts({ email: HELPER_TEXT.emailValidation });
    }

    if (validateEmail(value)) {
      setErrorFieldsStatus({ ...errorFieldsStatus, email: false });
      updateFormHelperTexts({ email: '' });
    }

    updateFormValues({ email: value });
  };

  const handleBlurEmail = () => {
    if (formState.email && !validateEmail(formState.email)) {
      updateFormHelperTexts({ email: HELPER_TEXT.emailValidation });

      setErrorFieldsStatus({ ...errorFieldsStatus, email: true });
    } else {
      updateFormHelperTexts({ email: '' });
      setErrorFieldsStatus({ ...errorFieldsStatus, email: false });
    }
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFormHelperTexts({ phone: '' });

    updateFormValues({ phone: event.target.value });
  };

  const handleChangeAddress =
    (field: keyof typeof initialFormState.address) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length >= MAX_ADDRESS_LENGTH) return;

      setErrorFieldsStatus({ ...errorFieldsStatus, address: { ...errorFieldsStatus.address, [field]: false } });

      updateFormValues({ address: { ...(formState.address as UserAddress), [field]: event.target.value } });
    };

  const handleSelectState = (event: SyntheticEvent, state: AutocompleteOption | null) => {
    if (state) {
      updateFormValues({ address: { ...(formState.address as UserAddress), state: state.label } });
      setErrorFieldsStatus({ ...errorFieldsStatus, address: { ...errorFieldsStatus.address, state: false } });
    }
  };

  const handleContactMethodChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    updateFormValues({
      preferredMethodOfContact: {
        ...formState?.preferredMethodOfContact,
        [event.target.name]: checked,
      } as ContactMethod,
    });

    if (checked) {
      updateFormHelperTexts({ contactMethod: '' });
    }
  };

  const handleSelectPlanEnrollment = (event: SyntheticEvent, enrollment: AutocompleteOption | null) => {
    if (enrollment) {
      const [season, year] = enrollment.label.split(' ');

      updateFormValues({ planEnrollment: { season: season as Season, year: +year } });
    }
  };

  const handleSaveContactForm = () => {
    const checkedErrorFields: ErrorFieldsStatus = {
      email: !!formState?.preferredMethodOfContact?.email && !validateEmail(formState.email ?? ''),
      phone:
        !!(formState?.preferredMethodOfContact?.phone || formState?.preferredMethodOfContact?.text) &&
        (!formState.phone || phoneNumberError),
      address: {
        address: !!formState?.preferredMethodOfContact?.mail && !formState?.address?.address,
        apartment: !!formState?.preferredMethodOfContact?.mail && !formState?.address?.apartment,
        city: !!formState?.preferredMethodOfContact?.mail && !formState?.address?.city,
        state: !!formState?.preferredMethodOfContact?.mail && !formState?.address?.state,
        zip: !!formState?.preferredMethodOfContact?.mail && !formState?.address?.zip,
      },
      preferredMethodOfContact: !Object.values(formState?.preferredMethodOfContact ?? {}).some((method) => method),
    };

    if (checkedErrorFields.email) {
      updateFormHelperTexts({ email: HELPER_TEXT.emailRequired });
    }

    if (checkedErrorFields.phone) {
      updateFormHelperTexts({ phone: HELPER_TEXT.phoneRequired });
    }

    if (checkedErrorFields.preferredMethodOfContact) {
      updateFormHelperTexts({ contactMethod: HELPER_TEXT.contactMethodRequired });
    }

    setErrorFieldsStatus(checkedErrorFields);

    if (
      !checkedErrorFields.email &&
      !checkedErrorFields.phone &&
      !Object.values(checkedErrorFields.address).some((field) => field) &&
      !checkedErrorFields.preferredMethodOfContact
    ) {
      resetOptInConnectionData();

      onGoNext();

      void saveUserSharingPreferences({ personalInfo: formState });
    }
  };

  return (
    <StyledDialog data-testid="contactFormDialog" open={isOpenOptInContactForm}>
      <CoreDialogTitle data-testid="contactFormTitle" sx={sxStyles.title}>
        Confirm your contact information
      </CoreDialogTitle>

      <CoreDialogContent data-testid="contactFormContent" sx={sxStyles.content}>
        <CoreTypography variant="subtitle3" sx={sxStyles.subtitle}>
          Contact information
        </CoreTypography>

        <CoreTypography>
          Please fill in your contact information. Schools may use this to reach out to you about their programs.
        </CoreTypography>

        <CoreBox sx={sxStyles.contactInfo}>
          <FormControl fullWidth size="small">
            <CoreTextField
              data-testid="emailTextField"
              label="Personal email"
              value={formState.email}
              required={formState.preferredMethodOfContact?.email}
              error={!!formHelperTexts.email}
              helperText={formHelperTexts.email}
              onChange={handleChangeEmail}
              onBlur={handleBlurEmail}
            />
          </FormControl>

          <FormControl fullWidth size="small">
            <InputMask
              name="phone"
              label="Phone number"
              value={formState.phone}
              helperText={formHelperTexts.phone}
              isRequired={formState.preferredMethodOfContact?.phone || formState.preferredMethodOfContact?.text}
              onChange={handlePhoneNumberChange}
              onError={(hasError) => {
                setPhoneNumberError(hasError);
              }}
            />
          </FormControl>
        </CoreBox>

        <CoreTypography variant="subtitle3" sx={sxStyles.subtitle}>
          Address
        </CoreTypography>

        <CoreBox sx={sxStyles.address}>
          <FormControl sx={sxStyles.street} fullWidth size="small">
            <CoreTextField
              data-testid="streetTextField"
              label="Street"
              value={formState?.address?.address}
              required={formState.preferredMethodOfContact?.mail}
              error={errorFieldsStatus.address.address}
              onChange={handleChangeAddress('address' as keyof typeof initialFormState.address)}
            />
          </FormControl>

          <FormControl sx={sxStyles.textField} fullWidth size="small">
            <CoreTextField
              data-testid="appartmentTextField"
              label="Apartment #"
              value={formState?.address?.apartment}
              required={formState.preferredMethodOfContact?.mail}
              error={errorFieldsStatus.address.apartment}
              onChange={handleChangeAddress('apartment' as keyof typeof initialFormState.address)}
            />
          </FormControl>
        </CoreBox>

        <CoreBox sx={sxStyles.address}>
          <FormControl sx={sxStyles.textField} fullWidth size="small">
            <CoreTextField
              data-testid="cityTextField"
              label="City"
              value={formState?.address?.city}
              required={formState.preferredMethodOfContact?.mail}
              error={errorFieldsStatus.address.city}
              onChange={handleChangeAddress('city' as keyof typeof initialFormState.address)}
            />
          </FormControl>

          <MuiAutocomplete
            data-testid="statesAutocomplete"
            sx={sxStyles.textField}
            size="small"
            options={STATES_OF_USA}
            value={selectedState}
            onChange={handleSelectState}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`State ${formState.preferredMethodOfContact?.mail ? '*' : ''}`}
                error={errorFieldsStatus.address.state}
              />
            )}
            clearIcon={null}
            noOptionsText={renderNoOptionsText()}
          />

          <FormControl sx={sxStyles.textField} fullWidth size="small">
            <CoreTextField
              data-testid="zipTextField"
              sx={sxStyles.zip}
              type="number"
              label="ZIP"
              value={formState?.address?.zip}
              required={formState.preferredMethodOfContact?.mail}
              error={errorFieldsStatus.address.zip}
              onChange={handleChangeAddress('zip' as keyof typeof initialFormState.address)}
            />
          </FormControl>
        </CoreBox>

        {Object.values(errorFieldsStatus.address).some((field) => field) && (
          <CoreTypography data-testid="adressHelperText" sx={sxStyles.helperText} variant="caption">
            {`Please fill out your ${Object.entries(errorFieldsStatus.address)
              .reduce(
                (acc, [label, value]) => (value ? [...acc, ADDRESS_HELPER_TEXT_LABELS[label]] : acc),
                [] as string[],
              )
              .join(', ')}.`}
          </CoreTypography>
        )}

        <CoreTypography variant="subtitle3" sx={sxStyles.subtitle}>
          Preferred method of contact
        </CoreTypography>

        <CoreFormGroup data-testid="preferredMethodCheckboxGroup" sx={sxStyles.checkboxGroup} row>
          <CoreFormControlLabel
            control={
              <CoreCheckbox
                data-testid="byEmail"
                name="email"
                value={formState.preferredMethodOfContact?.email}
                checked={formState.preferredMethodOfContact?.email}
                onChange={handleContactMethodChange}
              />
            }
            label="by email"
          />

          <CoreFormControlLabel
            control={
              <CoreCheckbox
                data-testid="byMail"
                name="mail"
                value={formState.preferredMethodOfContact?.mail}
                checked={formState.preferredMethodOfContact?.mail}
                onChange={handleContactMethodChange}
              />
            }
            label="by mail"
          />

          <CoreFormControlLabel
            control={
              <CoreCheckbox
                data-testid="byText"
                name="text"
                value={formState.preferredMethodOfContact?.text}
                checked={formState.preferredMethodOfContact?.text}
                onChange={handleContactMethodChange}
              />
            }
            label="by phone (text)"
          />

          <CoreFormControlLabel
            control={
              <CoreCheckbox
                data-testid="byPhone"
                name="phone"
                value={formState.preferredMethodOfContact?.phone}
                checked={formState.preferredMethodOfContact?.phone}
                onChange={handleContactMethodChange}
              />
            }
            label="by phone (call)"
          />
        </CoreFormGroup>

        {errorFieldsStatus.preferredMethodOfContact && (
          <CoreTypography data-testid="contactMethodHelperText" sx={sxStyles.helperText} variant="caption">
            {formHelperTexts.contactMethod}
          </CoreTypography>
        )}

        <CoreTypography variant="subtitle3" sx={sxStyles.subtitle}>
          When do you plan to enroll?
        </CoreTypography>

        <MuiAutocomplete
          data-testid="planEnrollment"
          sx={sxStyles.planEnrollment}
          size="small"
          options={PLAN_ENROLLMENT_OPTIONS}
          value={selectedPlanEnrollment}
          onChange={handleSelectPlanEnrollment}
          renderInput={(params) => <TextField {...params} label="I plan on enrolling" />}
          clearIcon={null}
          noOptionsText={renderNoOptionsText()}
        />
      </CoreDialogContent>

      <DialogFooter
        goBackButtonTitle="Previous"
        goNextButtonTitle="Save"
        goNextActionButtonTitle="Saving"
        onGoBack={onGoBack}
        onGoNext={handleSaveContactForm}
      />
    </StyledDialog>
  );
};
