import { createWithEqualityFn } from 'zustand/traditional';

import { v4 as uuidv4 } from 'uuid';

import { CoreSnackbarProps } from '@youscience/khaleesi';

export interface NotifyOptions extends CoreSnackbarProps {
  key?: string;
}

export interface NotificationStore {
  notifications: Array<NotifyOptions>;
  notificationInfo?: NotifyOptions | undefined;
  notify: (notification: NotifyOptions) => void;
  setNotificationInfo: (notificationInfo?: NotifyOptions) => void;
  setNotifications: (notificationArr: NotifyOptions[]) => void;
}

const initialState = {
  notifications: [],
  notificationInfo: undefined,
};

export const useNotificationStore = createWithEqualityFn<NotificationStore>()(
  (set, get) => ({
    ...initialState,

    notify(notification: NotifyOptions) {
      const key = uuidv4();

      set({
        notificationInfo: {
          ...notification,
          key,
          open: true,
        },
        notifications: [...get().notifications, { ...notification, key, open: true }],
      });
    },

    setNotificationInfo(notificationInfo?: NotifyOptions) {
      set({
        notificationInfo: notificationInfo || undefined,
      });
    },

    setNotifications(notifications: NotifyOptions[]) {
      set({
        notifications: [...notifications],
      });
    },
  }),
  Object.is,
);

export const notify: (notifyOptions: NotifyOptions) => void = (notifyOptions: NotifyOptions) => {
  const closure = useNotificationStore.getState().notify(notifyOptions);

  return closure;
};
