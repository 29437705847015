import { createWithEqualityFn } from 'zustand/traditional';

import { IpedsCardInfo, TenantSharing, TenantSharingType, UserPreferences } from '@youscience/college-service-common';

import {
  STEPS as baseSteps,
  SchoolSelectionData as baseSchoolSelectionData,
  SchoolByState,
  SchoolSelectionStore as baseSchoolSelectionStore,
} from '@interfaces/selectSchool';
import { baseService } from '@services/base.service';

import useNotificationStore from '@store/NotificationStore';
import useUserPreferencesStore from '@store/UserPreferencesStore';

export const STEPS = {
  ...baseSteps,
  noteMessage: 0,
};

export interface SchoolSelectionData extends baseSchoolSelectionData {
  isConfirmToSelection?: boolean;
  isGrantAccessToYSProfile?: boolean;
  isCollegeOriginatingTenant?: boolean;
  isNotToBeAskedToShare?: boolean;
  tenantId?: number | null;
  enrolledSchool?: TenantSharing;
}

interface SchoolSelectionStore extends baseSchoolSelectionStore {
  schoolSelectionData: SchoolSelectionData;
  setSchoolSelectionData: (data: Partial<SchoolSelectionData>) => void;
  grantAccessToYSProfile: (school: IpedsCardInfo, isShare: boolean) => Promise<void>;
  getSchoolInfo: () => Promise<IpedsCardInfo | void>;
}

const initialState = {
  schoolSelectionData: {
    activeStep: STEPS.step1,
    state: null,
    school: null,
    schoolListOptions: [],
    isConfirmToSelection: true,
    isGrantAccessToYSProfile: false,
    isCollegeOriginatingTenant: false,
    schoolPreviewInfo: {} as IpedsCardInfo,
  },
  isPreviewCardDataLoading: false,
};

export const useSchoolSelectionStore = createWithEqualityFn<SchoolSelectionStore>()((set, get) => {
  return {
    ...initialState,

    setSchoolSelectionData: (data: Partial<SchoolSelectionData>) => {
      set({
        schoolSelectionData: { ...get().schoolSelectionData, ...data },
      });
    },

    getSchoolListByState: async () => {
      const {
        schoolSelectionData: { state },
      } = get();

      if (state) {
        const schoolList = await baseService.getAsync<SchoolByState[]>(`/college-search?state=${state.value}`);

        const schoolListOptions = schoolList.map(({ id, name, city, zip }) => {
          return {
            label: `${name} (${zip}, ${city})`,
            value: id.toString(),
          };
        });

        set({
          schoolSelectionData: { ...get().schoolSelectionData, schoolListOptions },
        });
      }
    },

    getSchoolInfo: async () => {
      const {
        schoolSelectionData: { school, schoolPreviewInfo },
      } = get();

      if (school?.value && schoolPreviewInfo.id !== +school.value) {
        set({ isPreviewCardDataLoading: true });

        const { tenantId } = await baseService.getAsync<{ tenantId: number }>(`/tenant/${school.value}`);

        const [schoolPreviewInfo] = await baseService.getAsync<IpedsCardInfo[]>(`/card-info?id=${school.value}`);

        set({
          schoolSelectionData: {
            ...get().schoolSelectionData,
            tenantId,
            schoolPreviewInfo,
          },
          isPreviewCardDataLoading: false,
        });

        return schoolPreviewInfo;
      }

      return Promise.resolve();
    },

    saveSchoolSelection: async () => {
      const {
        schoolSelectionData: { schoolPreviewInfo },
      } = get();

      const response = await baseService.postAsync<UserPreferences, unknown>(`/user/enrolled/${schoolPreviewInfo.id}`);

      useUserPreferencesStore.getState().setUserPreferences(response);
    },

    deleteSchoolSelection: async (schoolId: number) => {
      const { setSchoolSelectionData } = get();

      const response = await baseService.deleteAsync<UserPreferences>(`/user/enrolled/${schoolId}`);

      useUserPreferencesStore.getState().setUserPreferences(response);

      setSchoolSelectionData(initialState.schoolSelectionData);
    },

    grantAccessToYSProfile: async (school: IpedsCardInfo, isShare: boolean) => {
      const response = await baseService.postAsync<UserPreferences, unknown>(`/user/tenant-sharing/${school.id}?`, {
        question: 'Do you agree to grant access to your YouScience profile?',
        share: isShare,
        shareType: TenantSharingType.ENROLLMENT,
      });

      useUserPreferencesStore.getState().setUserPreferences(response);

      if (isShare) {
        useNotificationStore.getState().notify({
          title: `You are now sharing data with ${school.name}`,
          message: 'You can change these permissions at any time in the profile menu.',
          severity: 'success',
        });
      }
    },
  };
}, Object.is);
