import { useLocation } from 'react-router-dom';
import { CoreTypography, CoreBox } from '@youscience/khaleesi';
import { Divider } from '@mui/material';

import { ROUTES } from '@constants/ROUTES';

import useEducationPlannerStore from '@store/EducationPlannerStore';

import { usePlannerSidebar } from '@hooks/usePlannerSidebar';

import ListIcon from '@images/list.svg?react';
import ProgressIcon from '@images/progress.svg?react';
import RemovedTasksIcon from '@images/removed-tasks.svg?react';
import ChevronBottomIcon from '@images/chevron-bottom.svg?react';

import {
  sxStyles,
  StyledSidebarList,
  StyledSidebarItem,
  StyledRemovedTaskBtn,
  StyledToggle,
} from './MobilePlannerSidebar.styles';

export const MobilePlannerSidebar = () => {
  const location = useLocation();

  const { isSidebarOpen, isShowRemovedTasks, toggleSidebar, handleSidebarClick, handleOpenTaskArchivePage } =
    usePlannerSidebar();

  const { isTaskArchivePage } = useEducationPlannerStore((state) => state.educationPlannerData);

  return (
    <StyledSidebarList data-testid="sidebarList">
      {isSidebarOpen ? (
        <>
          <StyledSidebarItem
            to={ROUTES.EDUCATION_PLANNER}
            isSelected={location.pathname === ROUTES.EDUCATION_PLANNER && !isTaskArchivePage}
            onClick={handleSidebarClick}
          >
            <ListIcon />

            <CoreTypography variant="body2">College planning</CoreTypography>
          </StyledSidebarItem>

          <StyledSidebarItem
            to={ROUTES.EDUCATION_PLANNER_APPLICATIONS}
            isSelected={location.pathname === ROUTES.EDUCATION_PLANNER_APPLICATIONS}
            onClick={handleSidebarClick}
          >
            <ProgressIcon />

            <CoreTypography variant="body2">College applications</CoreTypography>
          </StyledSidebarItem>

          {isShowRemovedTasks && location.pathname === ROUTES.EDUCATION_PLANNER ? (
            <>
              <Divider />

              <StyledRemovedTaskBtn isActive={isTaskArchivePage} onClick={handleOpenTaskArchivePage}>
                <RemovedTasksIcon />

                <CoreTypography variant="body2">Removed tasks</CoreTypography>
              </StyledRemovedTaskBtn>
            </>
          ) : null}

          <Divider />
        </>
      ) : null}

      <CoreBox sx={sxStyles.toggleWrap} onClick={toggleSidebar}>
        <StyledToggle isSidebarOpen={isSidebarOpen}>
          <ChevronBottomIcon />
        </StyledToggle>
      </CoreBox>
    </StyledSidebarList>
  );
};
