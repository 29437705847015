import { Suspense, createContext, lazy, useContext, useEffect, useMemo, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { RouterProvider } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { educationConnectionsTheme as theme } from '@youscience/khaleesi';
import { isAxiosError } from 'axios';
import { UserProfile } from '@youscience/college-service-common';
import { CssBaseline, ThemeProvider } from '@mui/material';

import useUserInfoStore, { UserInfo } from '@store/UserInfoStore';
import useUserPreferencesStore from '@store/UserPreferencesStore';
import useHomePageStore, { TenantAdminAssignment } from '@store/HomePageStore';
import useEducationPlannerStore from '@store/EducationPlannerStore';
import useApplyingSchoolsPlannerStore from '@store/ApplyingSchoolsPlannerStore';
import useAuthStore from '@store/AuthStore';

import { discoveryService } from '@services/discovery.service';
import { baseService } from '@services/base.service';

import { AppRoutes } from '@routes/AppRoutes';

import { BRIGHTPATH_HOME_URL } from '@constants/externalRoutes';

import 'core-js/proposals/promise-with-resolvers';
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_TRACKING_ID,
};

TagManager.initialize(tagManagerArgs);

const BRAIN_GAMES_RESULTS_AVAILABLE = 'results_ready';
const Notification = lazy(() => import('@components/Notification'));
const LoadingContext = createContext<{ isLoading: boolean }>({ isLoading: false });

export const useLoadingContext = () => useContext(LoadingContext);

// TODO temporary local interface
interface UserProfileData extends UserProfile {
  tenantAdminAssignments: TenantAdminAssignment[];
}

function App() {
  const [initialLoad, setInitialLoad] = useState(false);

  const setAdminHomePageData = useHomePageStore((state) => state.setAdminHomePageData);
  const setLandingPageData = useHomePageStore((state) => state.setLandingPageData);
  const setUserInfo = useUserInfoStore((state) => state.setUserInfo);
  const setUserPreferences = useUserPreferencesStore((state) => state.setUserPreferences);
  const { setUserPlanning, setEducationPlannerData } = useEducationPlannerStore((state) => state);

  const getMe = useAuthStore((state) => state.getMe);
  const getAppliedSchool = useApplyingSchoolsPlannerStore((state) => state.getAppliedSchool);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
  (window as any).dataLayer.push({
    event: 'pageview',
  });

  useEffect(() => {
    const getInitialData = async () => {
      // TODO - This needs to be called once when the app first starts up in some sort of authorization layer that awaits until it is complete, THEN loads the application

      let discoveryMe;

      try {
        await getMe();

        discoveryMe = (await baseService.get(`/user/me`)) as unknown as { data: Record<string, unknown> };
      } catch (error) {
        if (isAxiosError(error)) {
          window.location.replace(BRIGHTPATH_HOME_URL);
        }
      }

      const userBio = (await discoveryService.get(`/user_bio`)) as unknown as { data: Record<string, unknown> };
      const userInfoData = { ...userBio.data } as unknown as UserInfo;

      const userProfile = await baseService.getAsync<UserProfileData>(`/user/profile`);

      await Promise.all([
        // TODO temporary decision
        setAdminHomePageData(userProfile.tenantAdminAssignments[0]),
        setUserInfo({
          ...userInfoData,
          userId: discoveryMe?.data?.id as number,
          notifyTenantAvailable: userProfile.notifyTenantAvailable ?? false,
          hasTakenBrainGames: discoveryMe?.data?.user_status === BRAIN_GAMES_RESULTS_AVAILABLE,
        }),
        setUserPlanning(userProfile.userPlanning),
        setEducationPlannerData({ currentGrade: (discoveryMe?.data?.grade as number) ?? 0 }),
        setUserPreferences(userProfile.userPreferences),
        setLandingPageData(userProfile.enrolledSchool),
        getAppliedSchool(),
      ]);

      setInitialLoad(true);
    };

    void getInitialData();
  }, []);

  const isLoading = useMemo(() => !initialLoad, [initialLoad]);
  const contextValue = useMemo(() => ({ isLoading }), [isLoading]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <LoadingContext.Provider value={contextValue}>
        <RouterProvider router={AppRoutes()} />
      </LoadingContext.Provider>

      <Suspense>
        <Notification />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
