import { useLocation } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';

import useHomePageStore from '@store/HomePageStore';

import { ROUTES } from '@constants/ROUTES';

import { useLoadingContext } from '@App';

import PlannerSidebar from '@components/PlannerSidebar';
import MobilePlannerSidebar from '@components/MobilePlannerSidebar';
import RecommenderSpaceSidebar from '@components/RecommenderSpaceSidebar';
import MobileRecommenderSpaceSidebar from '@components/MobileRecommenderSpaceSidebar';

export const useSidebarInit = () => {
  const location = useLocation();
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const { id } = useHomePageStore((state) => state.landingPageSchoolData);

  const isPlannerRoute = !id && location.pathname.includes(ROUTES.EDUCATION_PLANNER);
  const isRecommenderSpaceRoute = !id && location.pathname.includes(ROUTES.RECOMMENDER_SPACE);
  const { isLoading: isInitialDataLoading } = useLoadingContext();

  const isMobileSidebarRoute = (isPlannerRoute || isRecommenderSpaceRoute) && isMobileScreen;

  let desktopSidebar = null;
  let mobileSidebar = null;

  if (!isInitialDataLoading && isPlannerRoute && !isMobileScreen) {
    desktopSidebar = <PlannerSidebar />;
  } else if (!isInitialDataLoading && isRecommenderSpaceRoute && !isMobileScreen) {
    desktopSidebar = <RecommenderSpaceSidebar />;
  }

  if (!isInitialDataLoading && isPlannerRoute && isMobileScreen) {
    mobileSidebar = <MobilePlannerSidebar />;
  } else if (!isInitialDataLoading && isRecommenderSpaceRoute && isMobileScreen) {
    mobileSidebar = <MobileRecommenderSpaceSidebar />;
  }

  return {
    isMobileSidebarRoute,
    isMobileScreen,
    isInitialDataLoading,
    desktopSidebar,
    mobileSidebar,
  };
};
