import { CoreTypography, CoreDialog } from '@youscience/khaleesi';

import useSchoolSelectionStore from '@store/SchoolSelectionStore';
import useHomePageStore from '@store/HomePageStore';

import DialogFooter from '@components/DialogFooter';

import { sxStyles, StyledDialogContent } from './VerificationMessage.styles';

interface VerificationMessageProps {
  isOpenVerificationMessage: boolean;
  onCloseVerificationMessage: () => void;
}

export const VerificationMessage = (props: VerificationMessageProps) => {
  const { isOpenVerificationMessage, onCloseVerificationMessage } = props;

  const {
    landingPageSchoolData: { cardInfo },
  } = useHomePageStore((state) => state);

  const { deleteSchoolSelection, grantAccessToYSProfile } = useSchoolSelectionStore((state) => state);

  const { getLandingPageData } = useHomePageStore((state) => state);

  const handleDeleteSchool = async () => {
    const requests = [grantAccessToYSProfile(cardInfo, false)];

    if (deleteSchoolSelection) {
      requests.push(deleteSchoolSelection(cardInfo.id));
    }

    await Promise.all(requests);

    await getLandingPageData();

    onCloseVerificationMessage();
  };

  return cardInfo ? (
    <CoreDialog sx={sxStyles.wrap} open={isOpenVerificationMessage}>
      <StyledDialogContent>
        <CoreTypography sx={sxStyles.title} variant="h3">
          Are you sure?
        </CoreTypography>

        <CoreTypography>
          {/* eslint-disable max-len */}
          {`If you change your in-platform status to unenrolled from ${cardInfo.name}, the system will redirect you to explore all colleges and programs.`}
        </CoreTypography>
      </StyledDialogContent>

      <DialogFooter
        goBackButtonTitle="Cancel"
        goNextButtonTitle="Confirm"
        goNextActionButtonTitle="Confirming"
        onGoBack={onCloseVerificationMessage}
        onGoNext={handleDeleteSchool}
      />
    </CoreDialog>
  ) : null;
};
