import { createWithEqualityFn } from 'zustand/traditional';

import { UserDocument } from '@youscience/user-service-common';
import { userService } from '@services/user.api.service';
import { Subset } from '../../../types';
import useAuthStore from '@store/AuthStore';

interface UserStore {
  updateUser: (userData: Subset<UserDocument>) => Promise<void>;
  getUserAge: () => number;
}

const initialState: UserStore = {
  updateUser: async () => {},
  getUserAge: () => 0,
};

export const useUserStore = createWithEqualityFn<UserStore>()(
  () => ({
    ...initialState,

    updateUser: async (userDocument: Subset<UserDocument>) => {
      const { isAuthenticated, userData } = useAuthStore.getState().authSession;

      if (isAuthenticated && userData) {
        const updatedData = {
          ...userData,
          ...userDocument,
          profile: {
            ...userData.profile,
            ...userDocument.profile,
          },
        };

        await userService.updateUser(userData.userId, updatedData);
        await useAuthStore.getState().getMe();
      }
    },

    getUserAge: () => {
      const { isAuthenticated } = useAuthStore.getState().authSession;
      const { userData } = useAuthStore.getState().authSession;

      if (isAuthenticated && userData) {
        const dateOfBirth = userData.profile?.dateOfBirth;

        if (dateOfBirth) {
          const today = new Date();
          const birthDate = new Date(dateOfBirth);
          let age = today.getFullYear() - birthDate.getFullYear();
          const month = today.getMonth() - birthDate.getMonth();

          if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age -= 1;
          }

          return age;
        }
      }

      return 0;
    },
  }),
  Object.is,
);
